import { useEffect, useState } from "react";

export const isNextGameToday =
	window.nextGameAt - Date.now() < 6 * 60 * 60 * 1000;

const calculateTimeLeft = (then) => {
	const now = new Date();
	const difference = then - now;
	if (difference < 0) return null;
	const timeLeft = {
		days: Math.floor(difference / (1000 * 60 * 60 * 24)),
		hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
		minutes: Math.floor((difference / 1000 / 60) % 60),
		seconds: Math.floor((difference / 1000) % 60),
	};

	return timeLeft;
};

const CountDown = () => {
	const targetTime = window.nextGameAt;
	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetTime));

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft(targetTime));
		}, 1000);
		return () => clearTimeout(timer);
	});

	return timeLeft === null
		? "00:00:00"
		: (timeLeft.hours < 10 ? "0" + timeLeft.hours : timeLeft.hours) +
				":" +
				(timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes) +
				":" +
				(timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds);
};

export default CountDown;
