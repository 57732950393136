import { useState } from "react";
import { HelpIcon } from "./Icons";

const HCP_AVG = 30;
const EXTRA_HCP_TABLE = 5;

const getHCPAtIndex = (previousGames, index) => {
	const slicedGames = [...previousGames].slice(
		index - HCP_AVG < 0 ? 0 : index - HCP_AVG,
		index
	);
	const hcp = (
		slicedGames
			.map((game) => (game.guesses < 0 ? 3 : game.guesses - 4))
			.reduce((c, game) => game + c, 0) / slicedGames.length
	).toFixed(2);
	return hcp;
};

const MyHCP = ({ previousGames }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [showTable, setShowTable] = useState(false);
	const [showAllGames, setShowAllGames] = useState(false);

	const hcp = getHCPAtIndex(previousGames, previousGames.length);
	const sliceIndex =
		previousGames.length > HCP_AVG + EXTRA_HCP_TABLE
			? previousGames.length - (HCP_AVG + EXTRA_HCP_TABLE)
			: 0;

	const gamesToDisplay = [...previousGames]
		.map((game, index) => ({
			...game,
			index,
			hcp: getHCPAtIndex(previousGames, index + 1),
		}))
		.slice(showAllGames ? 0 : sliceIndex)
		.reverse();

	return (
		<div>
			<div className="hcp" onClick={() => setIsExpanded(!isExpanded)}>
				<strong>HCP</strong> {hcp} <HelpIcon />
			</div>
			{isExpanded && (
				<div className="hcp-howto">
					<h4>⛳ Handicap i Ordel</h4>
					<p>
						Handicap (HCP) fungerar ungefär som i golf. Det är ett snitt av din
						prestation. Klarar du att gissa ordet på 4 försök så är det par (0).
						3 försök är birdie (-1). 5 försök är bogey (+1), osv. Misslyckat ord
						ger +3. Ditt aktuella HCP är ett glidande medelvärde, där endast de
						30 senaste orden gäller.
					</p>
					<button
						className="open-my-games"
						style={{ marginTop: 0 }}
						onClick={() => setShowTable(!showTable)}
					>
						{showTable ? "Dölj" : "Visa"} min HCP-tabell
					</button>
					{showTable && (
						<div style={{ margin: "0 -1rem" }}>
							<table style={{ width: "100%" }}>
								<thead>
									<tr>
										<th>Ordel</th>
										<th>Försök</th>
										<th>Resultat</th>
										<th>Nytt HCP</th>
									</tr>
								</thead>
								<tbody>
									{gamesToDisplay.map((game) => (
										<tr
											key={game.id}
											style={
												game.index < previousGames.length - HCP_AVG
													? { color: "var(--color-gray400)" }
													: null
											}
										>
											<td>
												<strong>#{game.id}</strong>
											</td>
											<td>
												{game.guesses < 0 ? "❌" : game.guesses}{" "}
												<div
													className={
														"small-guess-circle" +
														(game
															? game.guesses > 0
																? " success"
																: " fail"
															: "")
													}
													style={
														game && game.guesses > 0
															? {
																	background:
																		"var(--color-stats" + game.guesses + ")",
																	width: game.guesses * 8 + "px",
															  }
															: {
																	background: "var(--color-wrong)",
															  }
													}
												/>
											</td>
											<td>{game.guesses < 0 ? 3 : game.guesses - 4}</td>
											<td>{game.hcp}</td>
										</tr>
									))}
								</tbody>
							</table>
							{sliceIndex > 0 && (
								<button
									className="open-my-games"
									style={{ margin: "0.5rem" }}
									onClick={() => setShowAllGames(!showAllGames)}
								>
									{showAllGames
										? "Dölj äldre spel"
										: `Visa alla ${previousGames.length} spel`}
								</button>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default MyHCP;
