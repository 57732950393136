import React, { useState } from "react";
import { LinkIcon } from "./Icons";

const CreateAccountCta = ({ openLogin }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					height: "43px",
					marginTop: "-43px",
				}}
			>
				<button
					style={{
						display: "flex",
						justifyContent: "center",
						padding: 0,
						background: "transparent",
						border: 0,
						color: "var(--color-gray300)",
						cursor: "pointer",
					}}
					onClick={() => setIsExpanded(!isExpanded)}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							border: "1px solid var(--color-gray600)",
							borderRadius: "0.5rem",
							fontSize: "0.75rem",
							padding: "0.25rem 0.5rem",
						}}
					>
						<span
							style={{
								color: "var(--color-wrong)",
								width: "1rem",
								display: "inline-block",
								marginRight: "0.5rem",
							}}
						>
							<LinkIcon />
						</span>
						<span>Offline-läge</span>
					</div>
				</button>
			</div>
			{isExpanded && (
				<div className="account-cta">
					<div style={{ display: "flex", alignItems: "center" }}>
						<span
							style={{
								color: "var(--color-wrong)",
								width: "1.5rem",
								display: "inline-block",
								marginRight: "0.5rem",
							}}
						>
							<LinkIcon />
						</span>
						<h3>Förlora inte din historik!</h3>
					</div>
					<p>Din statistik är bara sparad på din enhet.</p>
					<p>
						Du kan logga in på Ordel för att spara din historik i ditt
						Ordelkonto istället.
					</p>
					<button className="cta" onClick={openLogin}>
						Logga in
					</button>
				</div>
			)}
		</>
	);
};

export default CreateAccountCta;
