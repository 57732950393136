const { useState } = require("react");

const Contact = () => {
	const [openTab, setOpenTab] = useState(null);

	const toggleTab = (tab) => {
		if (openTab === tab) {
			setOpenTab(null);
		} else {
			setOpenTab(tab);
		}
	};

	return (
		<div className="contact">
			<button onClick={() => toggleTab("contact")}>Kontakt</button> |{" "}
			<button onClick={() => toggleTab("jordel")}>Jordel ❤🌍</button>
			{openTab === "contact" && (
				<div>
					skapat av{" "}
					<a
						href="https://adamandreasson.se"
						target="_blank"
						rel="noopener noreferrer"
					>
						adam
					</a>{" "}
					|{" "}
					<a
						href="mailto:adam@ordel.se"
						target="_blank"
						rel="noopener noreferrer"
					>
						adam@ordel.se
					</a>{" "}
				</div>
			)}
			{openTab === "jordel" && (
				<div>
					Ordel är klimatpositivt! Genom reklamintäkter motverkar Ordel
					CO2-avtrycket från 20 svenska hushåll ❄
				</div>
			)}
		</div>
	);
};

export default Contact;
