import React from "react";

const Announcement = () => {
	return (
		<div className="announcement">
			<h3>⛳ HCP-notis </h3>
			<p style={{ fontSize: "0.875rem" }}>
				Kul att så många finner nöje i HCP-funktionen i Ordel, som från början
				bara var en kul extra grej. Notera att beräkningen av HCP nyligen
				ändrades. Klicka på ditt HCP nedan för att se mer information!
			</p>
		</div>
	);
};

export default Announcement;
