import React from "react";

const getMobileOS = () => {
	const ua = navigator.userAgent;
	if (/android/i.test(ua)) {
		return "android";
	} else if (
		/iPad|iPhone|iPod/.test(ua) ||
		(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
	) {
		return "ios";
	}
	return null;
};

const AppPush = () => {
	const os = getMobileOS();

	if (!os) {
		return null;
	}

	return (
		<div className="app-push">
			<h3>Ordel är bättre i appen!</h3>
			<ul>
				<li>Definitioner från SAOL</li>
				<li>Påminnelser</li>
				<li>Behåll din statistik med Ordelkonto</li>
			</ul>
			{os === "ios" && (
				<a
					href="https://apps.apple.com/se/app/ordel/id1614693887"
					target="_blank"
					rel="noreferrer"
				>
					Ladda ner på App Store
				</a>
			)}
			{os === "android" && (
				<a
					href="https://play.google.com/store/apps/details?id=se.ordel"
					target="_blank"
					rel="noreferrer"
				>
					Ladda ner på Google Play
				</a>
			)}
		</div>
	);
};

export default AppPush;
