const Bar = ({ i, numGuesses, totalGuesses, isLastGame }) => {
	return (
		<div className={"guess-count" + (isLastGame ? " last-game" : "")}>
			<div className="index">{i + 1}</div>
			<div
				className="bar"
				style={{
					background: "var(--color-stats" + (i + 1) + ")",
					width:
						Math.max(Math.round((85 * numGuesses) / totalGuesses), 4) + "%",
				}}
			></div>
			<div className="count">{numGuesses}</div>
		</div>
	);
};

const DistributionChart = ({ stats, noGuesses }) => {
	if (!stats.previousGames) return null;
	const numGuessesN = (n) =>
		stats.previousGames.filter((game) => game.guesses === n).length;
	const bars = [...Array(6)].map((n, i) => ({
		i: i,
		numGuesses: numGuessesN(i + 1),
		isLastGame: noGuesses === i + 1,
	}));
	const longestBar = bars.reduce((a, b) =>
		a.numGuesses > b.numGuesses ? a : b
	).numGuesses;
	return (
		<div className="distribution-wrapper">
			<h5>Antal försök</h5>
			<div className="distribution">
				{bars.map((bar) => (
					<Bar
						key={bar.i}
						i={bar.i}
						totalGuesses={longestBar}
						numGuesses={bar.numGuesses}
						isLastGame={bar.isLastGame}
					/>
				))}
			</div>
		</div>
	);
};

export default DistributionChart;
