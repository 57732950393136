import { Fragment, useEffect, useRef } from "react";
import CountDown, { isNextGameToday } from "./CountDown";
import { BackspaceIcon } from "./Icons";

const Keyboard = (props) => {
	const rows = [
		["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "å"],
		["a", "s", "d", "f", "g", "h", "j", "k", "l", "ö", "ä"],
		["z", "x", "c", "v", "b", "n", "m"],
	];
	const keysRef = useRef();

	const keyClick = (e) => {
		const button = e.target.closest("button");
		if (!button?.dataset?.char) {
			return;
		}
		props.addChar(button.dataset.char);
	};
	useEffect(() => {
		if (!keysRef.current) {
			return;
		}
		const currentRef = keysRef.current;
		currentRef.addEventListener("click", keyClick);
		return () => {
			currentRef.removeEventListener("click", keyClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keysRef]);

	const getCharClass = (char) =>
		props.status[char] ? props.status[char].toLowerCase() : null;

	return (
		<div className="keys" ref={keysRef}>
			{rows.map((row, i) => (
				<Fragment key={row}>
					{i === 2 && <div className="spacer" />}
					{row.map((char) => (
						<button data-char={char} key={char} className={getCharClass(char)}>
							{char}
						</button>
					))}
					{i === 2 && (
						<div className="undo">
							<button onClick={() => props.deleteChar()} aria-label="Sudda">
								<BackspaceIcon />
							</button>
						</div>
					)}
				</Fragment>
			))}
			<div className="enter">
				{props.showCountdown && (
					<div className="nextgame">
						{isNextGameToday
							? "Dagens ordel kommer om"
							: "Spela nästa ordel om"}
						<br />
						<div className="countdown">
							<CountDown />
						</div>
					</div>
				)}
				<button
					onClick={() => props.submit()}
					className={"submit" + (props.isFinished ? " disable" : "")}
				>
					Spela
				</button>
			</div>
		</div>
	);
};

export default Keyboard;
