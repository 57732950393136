import { Fragment } from "react";

const HowTo = (props) => (
	<Fragment>
		<h2>Så spelar du</h2>
		<p>Gissa dagens ord på max 6 försök!</p>
		<p>Du måste gissa på riktiga svenska ord med 5 bokstäver.</p>
		<p>Varje gissning ger ledtrådar som hjälper dig hitta svaret.</p>
		<div className="example">
			<h3>Exempel på gissning</h3>
			<div className="tiles howto">
				<div className="wrong">h</div>
				<div className="kinda">u</div>
				<div className="wrong">s</div>
				<div className="wrong">e</div>
				<div className="correct">t</div>
			</div>
			<p>
				En <span className="correct">grön</span> ruta betyder att bokstaven är
				korrekt och på rätt plats.
			</p>
			<p>
				En <span className="kinda">lila</span> ruta betyder att bokstaven är
				korrekt men på <strong>fel</strong> plats.
			</p>
			<p>
				En <span className="wrong">svart</span> ruta betyder att bokstaven inte
				är med i ordet.
			</p>
			<p>
				PS. Tänk på att samma bokstav kan finnas med i ordet mer än en gång!
			</p>
		</div>
		<button className="play" onClick={props.play}>
			Spela
		</button>
	</Fragment>
);

export default HowTo;
