import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { SERVER_URL } from "./util";

export const getOfflineSavedGames = () => {
	const loadedOfflineStatisticsRaw = window.localStorage.getItem("stats");
	const offlineStats = loadedOfflineStatisticsRaw
		? JSON.parse(loadedOfflineStatisticsRaw)
		: null;
	const offlineSavedGames = offlineStats ? offlineStats.previousGames : null;
	return offlineSavedGames;
};

export const getOfflineSavedGameState = () => {
	const loadedOfflineRaw = window.localStorage.getItem("gameState");
	const offlineState = loadedOfflineRaw ? JSON.parse(loadedOfflineRaw) : null;
	return offlineState;
};

export const getShouldShowPrompt = (user) => {
	const shouldShowPrompt =
		user?.history &&
		user.history.length === 0 &&
		getOfflineSavedGames().length > 0;
	return shouldShowPrompt;
};

const UploadHistoryPrompt = (props) => {
	const offlineSavedGames = getOfflineSavedGames();
	const shouldShowPrompt = getShouldShowPrompt(props.user);
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);

	const submit = async (e) => {
		e.preventDefault();
		if (isLoading) {
			return false;
		}

		const syncHistory = offlineSavedGames;
		const gameState = getOfflineSavedGameState();

		setIsLoading(true);
		try {
			const res = await fetch(SERVER_URL + "/user/history/upload", {
				method: "POST",
				credentials: "include",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ history: syncHistory, currentGame: gameState }),
			});
			if (res.status === 401) {
				setIsLoading(false);
				setFormError("Något gick fel! Testa att logga ut och logga in igen.");
				return false;
			}
			const data = await res.json();
			setFormError(null);
			setIsLoading(false);
			console.log(data);
			if (data.user) {
				window.location.replace("/");
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
			setFormError("Något gick fel! Försök igen senare.");
		}
		return false;
	};

	console.log({ offlineSavedGames });

	useEffect(() => {
		if (window.location.hash === "#developer") {
			window.localStorage.setItem("isDeveloper", "true");
			console.log("Super powers unlocked");
			return;
		}
		if (
			!Cookies.get("ordel_user") ||
			!offlineSavedGames ||
			(props?.user?.history && !shouldShowPrompt)
		) {
			console.log("Redirect!");
			window.location.replace("/");
		}
	}, [offlineSavedGames, shouldShowPrompt, props.user]);

	if (!shouldShowPrompt) {
		return null;
	}

	return (
		<form className="uploadhistory" onSubmit={submit}>
			<h2>Välkommen till Ordel, {props.user.user.displayName}!</h2>
			<h3>Spara din historik i ditt konto</h3>
			<p>
				Du har <strong>{offlineSavedGames.length}</strong> tidigare spel sparade
				offline. Vill du spara dem i ditt Ordelkonto?
			</p>
			<button className="big primary" type="submit">
				Spara min historik
			</button>
			<a className="button big tertiary" href="/">
				Nej tack
			</a>
			{formError && <div className="formerror">{formError}</div>}
		</form>
	);
};

export default UploadHistoryPrompt;
