import { useState } from "react";
import { useLocation } from "react-router-dom";
import { SERVER_URL } from "./util";

const FormInput = (props) => {
	const [showLabel, setShowLabel] = useState(props.disabled ? true : false);
	const onChange = (e) => {
		props.setValue(e.target.value);
		if (e.target.value.length > 0) {
			setShowLabel(true);
		} else {
			setShowLabel(false);
		}
	};
	return (
		<div className="input-wrap">
			<label className={showLabel ? "visible" : ""}>{props.label}</label>
			<input
				type={props.type}
				id={props.formId + "_" + props.name + "_input"}
				name={props.name}
				placeholder={props.label}
				value={props.value}
				required={props.required}
				minLength={props.minLength}
				maxLength={props.maxLength}
				autoComplete={props.autocomplete}
				onChange={(e) => onChange(e)}
				disabled={props.disabled}
			/>
		</div>
	);
};

const ResetPasswordForm = ({ params }) => {
	const [passwordInput, setPasswordInput] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

	const email = params.get("email");
	const token = params.get("token");

	const submit = async (e) => {
		e.preventDefault();
		if (isLoading) {
			return false;
		}

		setIsLoading(true);
		try {
			const res = await fetch(SERVER_URL + "/user/resetpassword", {
				method: "POST",
				credentials: "include",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: email,
					token,
					newPassword: passwordInput,
				}),
			});
			const data = await res.json();
			setFormError(null);
			setIsLoading(false);
			console.log(data);
			if (data.error) {
				setFormError(data.error);
			}
			setHasBeenSubmitted(true);
		} catch (e) {
			console.log(e);
			setIsLoading(false);
			setFormError("Något gick fel! Försök igen senare.");
		}
		return false;
	};

	if (hasBeenSubmitted) {
		return (
			<>
				<p>Ditt lösenord har återställts och du kan nu logga in!</p>
				<a href="/" className="continue-to-app">
					Fortsätt till Ordel
				</a>
			</>
		);
	}

	return (
		<>
			<p>Välj ditt nya lösenord</p>
			<form onSubmit={submit}>
				<FormInput
					type="password"
					name="password"
					formId="login"
					label="Lösenord"
					value={passwordInput}
					setValue={setPasswordInput}
					autocomplete="password"
					required
					minLength={8}
				/>
				<button type="submit" className="primary margin">
					{isLoading ? "Laddar..." : "Återställ lösenord"}
				</button>
				{formError && <div className="formerror">{formError}</div>}
			</form>
		</>
	);
};

export const ResetPasswordPage = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	return (
		<div
			className="account-wrapper"
			style={{
				maxWidth: "500px",
				margin: "0 auto",
				color: "#fff",
			}}
		>
			<h2>Återställ Ordellösenord</h2>
			<ResetPasswordForm params={searchParams} />
		</div>
	);
};
