import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import {
	AccountIcon,
	CloseIcon,
	HelpIcon,
	SmallCheckIcon,
	StatsIcon,
} from "./Icons";
import { NEXT_WORD_HOUR, SERVER_URL, stateEnum } from "./util";
import HowTo from "./HowTo";
import StatsWindow from "./StatsWindow";
import GameBoard from "./GameBoard";
import Keyboard from "./Keyboard";
import AccountWindow from "./AccountWindow";
import Cookies from "js-cookie";
import UploadHistoryPrompt from "./UploadHistoryPrompt";

const IS_DEVELOPER =
	process.env.NODE_ENV !== "production" ||
	window.localStorage.getItem("isDeveloper");

if (process.env.NODE_ENV !== "production") {
	window.stats = {
		current: { 1: 2, 2: 1, 3: 9, 4: 22, 5: 33, 6: 31, wrong: 20 },
		prev: [
			{
				id: 141,
				word: "kanna",
				bars: { 1: 2, 2: 3, 3: 11, 4: 28, 5: 33, 6: 21, wrong: 12 },
			},
			{
				id: 142,
				word: "farsa",
				bars: { 1: 3, 2: 2, 3: 8, 4: 25, 5: 34, 6: 25, wrong: 13 },
			},
		],
	};
	window.gameId = 238;
	const then = new Date();
	if (then.getHours() < NEXT_WORD_HOUR) {
		then.setHours(NEXT_WORD_HOUR, 0, 0, 0);
	} else {
		then.setHours(24 + NEXT_WORD_HOUR, 0, 0, 0);
	}
	window.nextGameAt = then;
	window.prevWord = "farsa";
}

const isGuessCorrect = (guess) =>
	guess.result.reduce((sum, x) => sum + x) === 5;

const didWinGame = (guesses) =>
	guesses.length < 1 ? false : isGuessCorrect(guesses[guesses.length - 1]);

const getHighestStreak = (arr) => {
	var i,
		temp,
		streak,
		length = arr.length,
		highestStreak = 0;

	for (i = 0; i < length; i++) {
		if (temp !== "" && temp === arr[i]) {
			streak++;
		} else {
			streak = 1;
		}
		temp = arr[i];
		if (streak > highestStreak) {
			highestStreak = streak;
		}
	}

	return highestStreak;
};

const getLastStreak = (arr) => {
	let streak = 0;
	for (var i = arr.length; i--; i >= 0) {
		if (arr[i] < 1) {
			break;
		}
		streak++;
	}
	return streak;
};

const AccountStatusAndIcon = ({ isLoggedIn }) => {
	return (
		<>
			<AccountIcon />
			{isLoggedIn && <SmallCheckIcon />}
		</>
	);
};

const getStatsStorageKey = () => {
	const isAuthSession = !!Cookies.get("ordel_user");
	const statsStorageKey = isAuthSession ? "stats_auth" : "stats";
	return statsStorageKey;
};
const getStateStorageKey = () => {
	const isAuthSession = !!Cookies.get("ordel_user");
	const statsStorageKey = isAuthSession ? "gameState_auth" : "gameState";
	return statsStorageKey;
};

const App = (props) => {
	const currentGameId = window.gameId;
	const blankGameState = {
		guesses: [],
		currentGuess: "",
		gameId: currentGameId,
	};
	const loadedGameStateRaw = window.localStorage.getItem(getStateStorageKey());
	const loadedGameState = loadedGameStateRaw
		? JSON.parse(loadedGameStateRaw)
		: blankGameState;
	const loadedStatisticsRaw = window.localStorage.getItem(getStatsStorageKey());
	const loadedStatistics = loadedStatisticsRaw
		? JSON.parse(loadedStatisticsRaw)
		: {
				gamesPlayed: 0,
				gamesWon: 0,
				winStreak: 0,
				longestStreak: 0,
				previousGames: [],
		  };

	// fix for people who played id 86 on day 85 when DST bugged the game. adjusts their previous games stats and lets them actually play game 86
	if (
		currentGameId === 86 &&
		loadedGameState.gameId === 86 &&
		loadedGameState.guesses.length > 0 &&
		loadedGameState.guesses[loadedGameState.guesses.length - 1].word === "farsa"
	) {
		const incorrectPreviousGame = loadedStatistics.previousGames.find(
			(g) => g.id === 86
		);
		const previousGameToOverwrite = loadedStatistics.previousGames.find(
			(g) => g.id === 85
		);
		if (incorrectPreviousGame && !previousGameToOverwrite) {
			incorrectPreviousGame.id = 85;
			window.localStorage.setItem(
				getStatsStorageKey(),
				JSON.stringify(loadedStatistics)
			);
		}
		loadedGameState.guesses = [];
		loadedGameState.currentGuess = "";
		loadedGameState.gameId = currentGameId;
	}
	const pollWindowOrNull =
		currentGameId === 59 &&
		loadedStatistics.gamesPlayed > 5 &&
		!window.localStorage.getItem("pollVotes")
			? null
			: null;
	const [hideWin, setHideWin] = useState(false);
	const [error, setError] = useState(null);
	const [gridAnimations, setGridAnimations] = useState({});
	const [keyboardGuessCount, setKeyboardGuessCount] = useState(-1);
	const [isLoading, setIsLoading] = useState(false);
	const [myStats, setMyStats] = useState(loadedStatistics);
	const [gameState, setGameState] = useState(
		loadedGameState.gameId < currentGameId ? blankGameState : loadedGameState
	);
	const [openedWindow, setOpenedWindow] = useState(
		loadedGameStateRaw ? pollWindowOrNull : "HELP"
	);
	const [lastKeyboardInput, setLastKeyboardInput] = useState({
		n: 0,
		key: null,
	});
	const [globalStats, setGlobalStats] = useState(window.stats);
	const [user, setUser] = useState(null);
	const [lastUserDataUpdate, setLastUserDataUpdate] = useState(0);
	const isFinished = useMemo(() => {
		if (gameState.guesses.length > 0) {
			const lastGuess = gameState.guesses[gameState.guesses.length - 1];
			if (isGuessCorrect(lastGuess)) {
				return true;
			}
		}
		if (gameState.guesses.length > 5) {
			return true;
		}
		return false;
	}, [gameState.guesses]);
	const keyboardStatus = useMemo(() => {
		const lettersMap = {};
		for (let i = 0; i < gameState.guesses.length; i++) {
			const guess = gameState.guesses[i];
			for (let c = 0; c < guess.result.length; c++) {
				const char = guess.word[c];
				const charResult = stateEnum(guess.result[c]);
				if (lettersMap[char] && lettersMap[char] === "CORRECT") {
					continue;
				}
				if (
					lettersMap[char] &&
					lettersMap[char] === "KINDA" &&
					charResult === "WRONG"
				) {
					continue;
				}
				lettersMap[char] = charResult;
			}
		}
		return lettersMap;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyboardGuessCount]);
	useEffect(() => {
		window.localStorage.setItem(
			getStateStorageKey(),
			JSON.stringify(gameState)
		);
	}, [gameState]);
	useEffect(() => {
		window.localStorage.setItem(getStatsStorageKey(), JSON.stringify(myStats));
	}, [myStats]);
	useEffect(() => {
		if (!lastKeyboardInput.input) return;
		if (lastUserDataUpdate < Date.now() - 1000 * 60 * 15) {
			getUserData();
		}
		if (lastKeyboardInput.input === "ENTER") {
			return submit();
		} else if (lastKeyboardInput.input === "BACKSPACE") {
			return deleteChar();
		} else {
			return addChar(lastKeyboardInput.input);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastKeyboardInput]);

	useEffect(() => {
		const keyDownEvent = (event) => {
			if (event.target.tagName.toUpperCase() === "INPUT") {
				return;
			}
			let input = null;
			const char = event.key.toLowerCase();
			if (event.ctrlKey || event.altKey || event.metaKey) {
				return;
			}
			if (char === "enter") {
				input = "ENTER";
			} else if (char === "backspace") {
				input = "BACKSPACE";
			} else if (char.length === 1 && /[a-zåäö]/.test(char)) {
				input = char.toLowerCase();
			}
			setLastKeyboardInput((prev) => ({ n: prev + 1, input: input }));
			if (input) {
				event.preventDefault();
				event.stopPropagation();
			}
		};
		document.addEventListener("keydown", keyDownEvent); //asd
		return () => document.removeEventListener("keydown", keyDownEvent);
	}, []);

	const addChar = (char) => {
		if (isFinished) {
			return;
		}
		setGameState((gs) => {
			if (gs.currentGuess.length >= 5) {
				shakeLine(gs.guesses.length);
				return gs;
			}
			return { ...gs, currentGuess: gs.currentGuess + char };
		});
	};

	const deleteChar = () => {
		if (gameState.currentGuess.length === 0) {
			return;
		}
		const newGuess = gameState.currentGuess.slice(
			0,
			gameState.currentGuess.length - 1
		);
		setGameState({ ...gameState, currentGuess: newGuess });
	};

	const startSpinningAnimation = (row) => {
		for (let i = 0; i < 5; i++) {
			setGridAnimations((a) => ({ ...a, [row + "" + i]: "secret" }));
			window.setTimeout(() => {
				setGridAnimations((a) => ({ ...a, [row + "" + i]: "spin" }));
			}, i * 50);
		}
	};
	const stopSpinningAnimation = (row, isInstant = false) => {
		if (isInstant) {
			setGridAnimations({});
			return;
		}
		for (let i = 0; i < 5; i++) {
			//setGridAnimations((a) => ({ ...a, [row + "" + i]: "spin" }));
			window.setTimeout(() => {
				setGridAnimations((a) => ({ ...a, [row + "" + i]: null }));
			}, i * 250);
		}
	};

	const showError = (err) => {
		const errorId = Date.now();
		const timeout = window.setTimeout(() => {
			setError((err) =>
				err?.id === errorId ? { ...err, hideMessage: true } : err
			);
			window.setTimeout(() => {
				if (err?.timeout) {
					window.clearTimeout(err.timeout);
				}
				setError((err) => (err?.id === errorId ? null : err));
			}, 500);
		}, 4500);
		setError({ ...err, timeout: timeout, id: errorId });
	};

	const shakeLine = (line) => {
		const errorId = Date.now();
		window.setTimeout(() => {
			setError((err) => (err?.id === errorId ? null : err));
		}, 500);
		setError({ shakeLine: line, id: errorId });
	};

	const submit = async () => {
		if (isFinished) {
			setOpenedWindow("STATS");
			return;
		}
		if (error?.timeout) {
			window.clearTimeout(error.timeout);
			setError(null);
		}
		const now = Date.now();
		if (window.nextGameAt < now) {
			alert(
				"Tiden är ute! Kl 06:00 släpptes ett nytt ord. Klicka OK för att gissa på det nya ordet."
			);
			window.location.reload();
			return;
		}
		if (gameState.currentGuess.length !== 5) {
			showError({
				message: "Gissa ett ord med fem bokstäver",
				shakeLine: gameState.guesses.length,
			});
			return;
		}
		setError(null);
		const startSpinTime = Date.now();
		startSpinningAnimation(gameState.guesses.length);
		setIsLoading(true);
		try {
			const res = await fetch(
				SERVER_URL +
					"/play?n=" +
					gameState.guesses.length +
					"&guess=" +
					gameState.currentGuess +
					"&id=" +
					currentGameId,
				{
					method: "GET",
					mode: "cors",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			const data = await res.json();
			setIsLoading(false);
			const endSpinTime = Date.now();
			const msDiff = endSpinTime - startSpinTime;
			if (data?.latestStats) {
				setGlobalStats({ ...globalStats, current: data.latestStats });
			}
			if (data?.letters) {
				const lastGuess = gameState.guesses.length >= 5;
				const newGuess = { word: gameState.currentGuess, result: data.letters };
				//await new Promise((r) => setTimeout(r, 200));
				await new Promise((r) => setTimeout(r, 500 - msDiff));
				setGameState({
					...gameState,
					guesses: [...gameState.guesses, newGuess],
					currentGuess: "",
				});
				stopSpinningAnimation(gameState.guesses.length);
				setHideWin(true);
				await new Promise((r) => setTimeout(r, 1000));
				if (isGuessCorrect(newGuess)) {
					const winMessages = [
						"Snyggt!",
						"Bra jobbat!",
						"Utmärkt!",
						"Där satt den!",
						"Synnerligen utomordentligt!",
					];
					showError({
						message:
							gameState.guesses.length === 0
								? "Idag har du flyt!"
								: winMessages[Math.floor(Math.random() * winMessages.length)],
					});
					const gameResult = {
						id: gameState.gameId,
						guesses: gameState.guesses.length + 1,
					};
					setMyStats((ms) => ({
						...ms,
						winStreak: ms.winStreak + 1,
						gamesPlayed: ms.gamesPlayed + 1,
						gamesWon: ms.gamesWon + 1,
						longestStreak:
							ms.winStreak + 1 > ms.longestStreak
								? ms.winStreak + 1
								: ms.longestStreak,
						previousGames: ms?.previousGames
							? [...ms.previousGames, gameResult]
							: [gameResult],
					}));
					window.setTimeout(() => {
						setHideWin(false);
						setOpenedWindow("STATS");
					}, 2000);
				} else {
					if (lastGuess) {
						const gameResult = {
							id: gameState.gameId,
							guesses: -1,
						};
						setMyStats((ms) => ({
							...ms,
							gamesPlayed: ms.gamesPlayed + 1,
							winStreak: 0,
							longestStreak:
								ms.winStreak > ms.longestStreak
									? ms.winStreak
									: ms.longestStreak,
							previousGames: ms?.previousGames
								? [...ms.previousGames, gameResult]
								: [gameResult],
						}));
						showError({ message: "❌ Bättre lycka imorgon!" });
						window.setTimeout(() => {
							setHideWin(false);
							setOpenedWindow("STATS");
						}, 2000);
					}
				}
				setKeyboardGuessCount(gameState.guesses.length + 1);
			} else {
				await new Promise((r) => setTimeout(r, 500 - msDiff));
				stopSpinningAnimation(gameState.guesses.length, true);
				if (data.error && data.error === "INVALID_WORD") {
					showError({
						message: "Gissa ett svenskt ord",
						shakeLine: gameState.guesses.length,
					});
					return;
				}
				showError({ message: "Anslutningen misslyckades" });
			}
		} catch (e) {
			showError({ message: "Anslutningen misslyckades" });
			setIsLoading(false);
			const endSpinTime = Date.now();
			const msDiff = endSpinTime - startSpinTime;
			await new Promise((r) => setTimeout(r, 500 - msDiff));
			stopSpinningAnimation(gameState.guesses.length, true);
		}
	};

	useEffect(() => {
		if (!user?.history) {
			return;
		}
		if (user.history.length < 1) {
			return;
		}
		const streakArray = user.history.map((g) => (g.guesses > 0 ? 1 : 0));
		const userMyStats = {
			gamesPlayed: user.history.length,
			gamesWon: user.history.filter((g) => g.guesses > 0).length,
			winStreak: getLastStreak(streakArray),
			longestStreak: getHighestStreak(streakArray),
			previousGames: user.history,
		};
		setMyStats(userMyStats);
	}, [user]);

	useEffect(() => {
		if (!user?.currentGame) {
			return;
		}
		console.log(user.currentGame);
		setGameState((gs) => ({ ...gs, guesses: user.currentGame }));
		setKeyboardGuessCount((gc) => gc * 5);
	}, [user]);

	const updateUser = (fullData) => {
		if (fullData) {
			setUser(fullData);
			return;
		}
		const userCookie = Cookies.get("ordel_user");
		if (userCookie) {
			const parsedCookie = JSON.parse(userCookie);
			if (parsedCookie?.username) {
				setUser((u) => (u?.user ? u : { user: parsedCookie }));
			}
		} else {
			setUser(null);
		}
	};

	const getUserData = async () => {
		const userCookie = Cookies.get("ordel_user");
		if (!userCookie) {
			return;
		}
		try {
			const res = await fetch(SERVER_URL + "/user", {
				method: "GET",
				credentials: "include",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
			});
			if (res.status === 401) {
				console.log("Session expired");
				Cookies.remove("ordel_user");
				window.location.reload();
				return false;
			}
			const data = await res.json();
			setUser(data);
			setLastUserDataUpdate(Date.now());
		} catch (e) {
			console.log(e);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		const onFocus = () => {
			getUserData();
		};
		window.addEventListener("focus", onFocus);
		return () => {
			window.removeEventListener("focus", onFocus);
		};
	}, []);

	useEffect(() => {
		updateUser();
		getUserData();
	}, []);

	if (props.showUserRamp) {
		return (
			<div className="app notranslate">
				<UploadHistoryPrompt user={user} myStats={myStats} />
			</div>
		);
	}

	return (
		<div className="app notranslate">
			{openedWindow && (
				<div className="modal-wrap">
					<div className="modal-overlay"></div>
					<div
						className="modal-content"
						onMouseDown={() => setOpenedWindow(null)}
					>
						<div className="modal" onMouseDown={(e) => e.stopPropagation()}>
							<div className="top">
								<button
									className="close"
									onClick={() => setOpenedWindow(null)}
									aria-label="Stäng"
								>
									<CloseIcon />
								</button>
							</div>
							{openedWindow === "HELP" && (
								<HowTo play={() => setOpenedWindow(null)} />
							)}
							{openedWindow === "STATS" && (
								<StatsWindow
									stats={myStats}
									gameState={gameState}
									user={user}
									isFinished={isFinished}
									winningWord={
										didWinGame(gameState.guesses)
											? gameState.guesses[gameState.guesses.length - 1].word
											: null
									}
									globalStats={globalStats}
									openLogin={() => setOpenedWindow("ACCOUNT")}
								/>
							)}
							{openedWindow === "ACCOUNT" && (
								<AccountWindow
									onClose={() => setOpenedWindow(null)}
									updateUser={updateUser}
									user={user}
									myStats={myStats}
								/>
							)}
						</div>
					</div>
				</div>
			)}
			<header>
				<button
					onClick={() => setOpenedWindow("HELP")}
					aria-label="Instruktioner"
				>
					<HelpIcon />
				</button>
				<span style={{ width: "2.5rem", flexGrow: 0 }}></span>
				<span>Ordel</span>
				<button
					onClick={() => setOpenedWindow("ACCOUNT")}
					title={
						user?.user?.username
							? "Inloggad som " + user?.user?.displayName
							: "Mitt konto"
					}
					style={{ position: "relative" }}
				>
					<AccountStatusAndIcon isLoggedIn={!!user} />
				</button>
				<button onClick={() => setOpenedWindow("STATS")} aria-label="Statistik">
					<StatsIcon />
				</button>
			</header>
			<main>
				<section className="board">
					<div className="center">
						{error?.message && (
							<div className={"error" + (error.hideMessage ? " hide" : "")}>
								<span>{error.message}</span>
							</div>
						)}
						<GameBoard
							gameState={gameState}
							isFinished={isFinished}
							animations={gridAnimations}
							error={error}
						/>
					</div>
				</section>
				<section className="keyboard">
					<Keyboard
						addChar={addChar}
						deleteChar={deleteChar}
						submit={submit}
						status={keyboardStatus}
						showCountdown={!hideWin && isFinished && !openedWindow}
						isFinished={!hideWin && isFinished}
					/>
				</section>
			</main>
		</div>
	);
};

export default App;
