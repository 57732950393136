import { useMemo } from "react";

export const getPerformanceSymbol = (num) =>
	num > 80 ? "🏆" : num > 66 ? "🥇" : num > 34 ? "🥈" : "🥉";

export const getRelativeNumber = (noGuesses, globalStats) => {
	if (!globalStats || noGuesses < 1) return null;
	const totalPercentage = 100 + globalStats.wrong;
	const percentageReduction = 100 / totalPercentage;

	let worsePlayers = globalStats.wrong;
	for (let i = 6; i > noGuesses; i--) {
		worsePlayers += globalStats[i];
	}
	worsePlayers *= percentageReduction;
	return Math.round(worsePlayers);
};

const RelativePerformanceToday = ({ noGuesses, globalStats }) => {
	const relativeNumber = useMemo(
		() => getRelativeNumber(noGuesses, globalStats),
		[noGuesses, globalStats]
	);

	if (!globalStats || noGuesses < 1 || isNaN(relativeNumber)) return null;
	const symbol = getPerformanceSymbol(relativeNumber);
	return (
		<div className="relative-performance">
			{symbol} Du behövde färre försök än <strong>{relativeNumber}%</strong> av
			spelare!
		</div>
	);
};

export default RelativePerformanceToday;
