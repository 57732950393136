import { Fragment, useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import AllGamesGrid from "./AllGamesGrid";
import Announcement from "./Announcement";
import AppPush from "./AppPush";
import Contact from "./Contact";
import CountDown, { isNextGameToday } from "./CountDown";
import CreateAccountCta from "./CreateAccountCta";
import DistributionChart from "./DistributionChart";
import { ShareIcon } from "./Icons";
import MyHCP from "./MyHCP";
import PreviousGame from "./PreviousGame";
import RelativePerformanceToday, {
	getPerformanceSymbol,
	getRelativeNumber,
} from "./RelativePerformanceToday";

const GoogleAd = ({ slot = "4657482030" }) => {
	useEffect(() => {
		if (slot === "4657482030") {
			window.adsbygoogle = window.adsbygoogle || [];
			window.adsbygoogle.push({});
		}
	}, [slot]);

	return (
		<ins
			className="adsbygoogle billpayer"
			data-ad-client="ca-pub-5864380456487179"
			data-ad-slot={slot}
		></ins>
	);
};

const TestAd = ({ code }) => {
	if (window.useABTest) {
		return <TestYollaAd />;
	}

	return <GoogleAd code={code} />;
};

const TestYollaAd = () => {
	return (
		<div className="billpayer">
			<div
				className="ympb_target"
				data-type="desktop-medrec-template"
				data-subtype="300x250"
			/>
		</div>
	);
};

const isShareUserAgent =
	/Mobi|Android/i.test(navigator.userAgent) &&
	!(
		/Android/i.test(navigator.userAgent) && /Firefox/i.test(navigator.userAgent)
	);
const shouldOpenShareMenu = navigator.canShare && isShareUserAgent;

const UnknownWordHint = () => (
	<div className="unknown-word-hint">
		❌ Dagens ord är synligt för alla efter att nästa ordel har släppts.
	</div>
);

const StatsWindow = ({
	stats,
	gameState,
	isFinished,
	winningWord,
	globalStats,
	user,
	openLogin,
}) => {
	const [copied, setCopied] = useState(false);
	const shareSymbol = useMemo(
		() =>
			getPerformanceSymbol(
				getRelativeNumber(
					winningWord ? gameState.guesses.length : 0,
					globalStats.current
				)
			),
		[winningWord, gameState, globalStats]
	);

	const shareText =
		"Ordel #" +
		gameState.gameId +
		" " +
		(winningWord ? gameState.guesses.length : "X") +
		"/6" +
		(winningWord ? " " + shareSymbol : "") +
		"\n" +
		gameState.guesses
			.map((g) =>
				g.result.map((c) => (c === -1 ? "⬛" : c === 0 ? "🟪" : "🟩")).join("")
			)
			.join("\n");

	return (
		<div className="stats-wrapper">
			{!user && stats.gamesPlayed > 7 && (
				<CreateAccountCta openLogin={openLogin} />
			)}
			<Fragment>
				<TestAd />
			</Fragment>

			<h2>Statistik</h2>

			<div className="stats">
				<div>
					<div className="number">{stats.gamesPlayed}</div>
					<div className="label">spelade</div>
				</div>
				<div>
					<div className="number">
						{stats.gamesPlayed > 0
							? Math.round((100 * stats.gamesWon) / stats.gamesPlayed)
							: 0}
					</div>
					<div className="label">vinst %</div>
				</div>
				<div>
					<div className="number">{stats.winStreak}</div>
					<div className="label">segersvit</div>
				</div>
				<div>
					<div className="number">{stats.longestStreak}</div>
					<div className="label">längsta svit</div>
				</div>
			</div>
			<DistributionChart
				stats={stats}
				noGuesses={winningWord ? gameState.guesses.length : 0}
			/>
			{isFinished && (
				<Fragment>
					{shouldOpenShareMenu ? (
						<button
							className="share"
							onClick={() => navigator.share({ text: shareText })}
						>
							<ShareIcon /> Dela mitt resultat
						</button>
					) : (
						<CopyToClipboard
							text={shareText}
							onCopy={() => setCopied(true)}
							options={{ format: "text/plain" }}
						>
							<button className="share">
								<ShareIcon /> Dela mitt resultat
							</button>
						</CopyToClipboard>
					)}
					{copied && (
						<div>Kopierat - klistra in ditt resultat i valfri app!</div>
					)}

					{gameState.gameId - 240 < 0 && <Announcement />}

					{window.stats && (
						<div className="prev-stats">
							<div className="table-head">
								<div>Tidigare ord</div>
								<div>Andras resultat</div>
							</div>
							{globalStats.prev.map((game) => (
								<PreviousGame
									word={game.word}
									bars={game.bars}
									key={game.id}
									id={game.id}
									previousGames={stats.previousGames}
								/>
							))}
							<PreviousGame
								word={winningWord}
								bars={globalStats.current}
								id={window.gameId}
								previousGames={stats.previousGames}
							/>
							{!winningWord && <UnknownWordHint />}
							<RelativePerformanceToday
								noGuesses={winningWord ? gameState.guesses.length : 0}
								globalStats={globalStats.current}
							/>
						</div>
					)}
					<AppPush />
					<div className="nextgame">
						<h4>
							{isNextGameToday
								? "Dagens ordel släpps om"
								: "Nästa ordel släpps om"}
						</h4>
						<div className="countdown">
							<CountDown />
						</div>
					</div>
					<MyHCP previousGames={stats.previousGames} />
					<AllGamesGrid
						previousGames={stats.previousGames}
						currentId={window.gameId}
					/>
				</Fragment>
			)}
			<Contact />
		</div>
	);
};

export default StatsWindow;
