const GameTile = (props) => {
	return (
		<div
			className={
				(props.tileData.state
					? props.animation
						? ""
						: props.tileData.state.toLowerCase()
					: "") +
				(props.animation ? " " + props.animation : "") +
				(props.shake ? " shake" : "")
			}
		>
			<span>{props.tileData.char}</span>
		</div>
	);
};

export default GameTile;
