import { NEXT_WORD_HOUR } from "./util";

const PreviousGame = (props) => {
	const word = props.word ? props.word.toUpperCase() : "?????";
	const daysSwedish = ["sön", "mån", "tis", "ons", "tor", "fre", "lör"];
	const statsNumbers = [1, 2, 3, 4, 5, 6];
	const now = new Date();
	if (now.getHours() >= NEXT_WORD_HOUR) {
		now.setHours(NEXT_WORD_HOUR, 0, 0, 0);
	} else {
		now.setHours(NEXT_WORD_HOUR - 24, 0, 0, 0);
	}
	const daysAgo = window.gameId - props.id;
	const gameDate = new Date(now - daysAgo * 1000 * 60 * 60 * 24);
	const dayName = daysSwedish[gameDate.getDay()];
	const isCompleted = props.previousGames
		? props.previousGames.some(
				(game) => game.id === props.id && game.guesses > -1
		  )
		: false;
	return (
		<div className="prevgame">
			<div className="day">{dayName}</div>
			<div
				className={
					"word" + (!props.word ? " unknown" : !isCompleted ? "" : " won")
				}
			>
				{[...word].map((l, i) => (
					<span key={i}>{l}</span>
				))}
			</div>
			{props.bars && (
				<div className="prevstats">
					<div
						className="graph"
						style={{
							width:
								(props.bars.wrong < 100
									? 100
									: 100 / (props.bars.wrong / 100)) + "%",
						}}
					>
						{statsNumbers.map((num) => (
							<div
								key={num}
								style={{
									width: props.bars[num] + "%",
									background: "var(--color-stats" + num + ")",
								}}
							></div>
						))}
					</div>
					<div className="graph">
						<div
							style={{
								width: Math.min(props.bars.wrong, 100) + "%",
								background: "var(--color-wrong)",
							}}
						></div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PreviousGame;
