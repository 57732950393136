import GameTile from "./GameTile";
import { stateEnum } from "./util";

const GameBoard = (props) => {
	const tileAt = (row, col) => {
		const currentGuess = props.gameState.currentGuess;
		if (props.gameState.guesses.length > row) {
			const guess = props.gameState.guesses[row];
			const word = guess.word;
			const state = stateEnum(guess.result[col]);
			return { char: word.charAt(col), state: state };
		}
		if (row === props.gameState.guesses.length) {
			if (currentGuess.length >= col && !props.isFinished) {
				return {
					char: currentGuess.charAt(col),
					state: currentGuess.length === col ? "input cursor" : "INPUT",
				};
			}
		}
		return { char: null, state: null };
	};
	return (
		<div className="tiles">
			{[...Array(6)].map((row, i) =>
				[...Array(5)].map((tile, j) => (
					<GameTile
						key={i + "" + j}
						tileData={tileAt(i, j)}
						animation={props.animations[i + "" + j]}
						shake={props.error && props.error?.shakeLine === i}
					/>
				))
			)}
		</div>
	);
};

export default GameBoard;
