import { useState } from "react";

const { CloseIcon } = require("./Icons");

const Game = ({ id, game }) => {
	return (
		<div
			title={
				"Ordel #" +
				id +
				"\n" +
				(game && game.guesses
					? game.guesses > 0
						? game.guesses + " försök"
						: "Misslyckades"
					: "Spelade ej")
			}
			className={
				"guess-circle" + (game ? (game.guesses > 0 ? " success" : " fail") : "")
			}
			style={
				game && game.guesses > 0
					? {
							background: "var(--color-stats" + game.guesses + ")",
					  }
					: null
			}
		>
			{game && game.guesses < 0 ? <CloseIcon /> : null}
		</div>
	);
};

const AllGamesGrid = ({ previousGames, currentId }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	if (!previousGames) return null;
	const sortedGames = [...previousGames].sort((a, b) => (a.id > b.id ? 1 : -1));
	if (sortedGames.length < 1) return null;
	const firstPlayedId = sortedGames[0].id;
	const totalGames = 1 + currentId - firstPlayedId;
	if (!isExpanded) {
		return (
			<button className="open-my-games" onClick={() => setIsExpanded(true)}>
				Visa tidigare spel
			</button>
		);
	}
	return (
		<div className="my-games-wrapper">
			<h5>Mina tidigare spel</h5>
			<div className="my-games">
				{[...Array(totalGames)].map((n, i) => (
					<Game
						key={i}
						id={firstPlayedId + i}
						game={sortedGames.find((g) => g.id === firstPlayedId + i)}
					/>
				))}
			</div>
		</div>
	);
};

export default AllGamesGrid;
