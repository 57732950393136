/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState } from "react";
import "./Account.css";
import { SERVER_URL } from "./util";
import Cookies from "js-cookie";
import { BackIcon } from "./Icons";
import { getShouldShowPrompt } from "./UploadHistoryPrompt";

const FormInput = (props) => {
	const [showLabel, setShowLabel] = useState(props.disabled ? true : false);
	const onChange = (e) => {
		props.setValue(e.target.value);
		if (e.target.value.length > 0) {
			setShowLabel(true);
		} else {
			setShowLabel(false);
		}
	};
	return (
		<div className="input-wrap">
			<label className={showLabel ? "visible" : ""}>{props.label}</label>
			<input
				type={props.type}
				id={props.formId + "_" + props.name + "_input"}
				name={props.name}
				placeholder={props.label}
				value={props.value}
				required={props.required}
				minLength={props.minLength}
				maxLength={props.maxLength}
				autoComplete={props.autocomplete}
				onChange={(e) => onChange(e)}
				disabled={props.disabled}
			/>
		</div>
	);
};

const RegisterWithEmailForm = ({ onUserData }) => {
	const [emailInput, setEmailInput] = useState("");
	const [passwordInput, setPasswordInput] = useState("");
	const [nameInput, setNameInput] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);

	const submit = async (e) => {
		e.preventDefault();
		if (isLoading) {
			return false;
		}

		setIsLoading(true);
		try {
			const res = await fetch(SERVER_URL + "/auth/register", {
				method: "POST",
				credentials: "include",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: emailInput,
					password: passwordInput,
					displayName: nameInput,
				}),
			});
			const data = await res.json();
			setFormError(null);
			setIsLoading(false);
			console.log(data);
			if (data.error) {
				setFormError(data.error);
			}
			if (data.user) {
				Cookies.set("ordel_user", JSON.stringify(data.user), { expires: 365 });
				onUserData(data);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
			setFormError("Något gick fel! Försök igen senare.");
		}
		return false;
	};

	return (
		<>
			<form onSubmit={submit}>
				<FormInput
					type="email"
					name="email"
					value={emailInput}
					formId="login"
					label="E-postadress"
					setValue={setEmailInput}
					autocomplete="email"
					required
					minLength={2}
				/>
				<FormInput
					type="password"
					name="password"
					formId="login"
					label="Lösenord"
					value={passwordInput}
					setValue={setPasswordInput}
					autocomplete="password"
					required
					minLength={8}
				/>
				<FormInput
					type="text"
					name="displayName"
					value={nameInput}
					formId="login"
					label="Visningsnamn"
					setValue={setNameInput}
					autocomplete="name"
					required
					minLength={1}
				/>
				<button type="submit" className="primary margin">
					{isLoading ? "Laddar..." : "Skapa konto"}
				</button>
				{formError && <div className="formerror">{formError}</div>}
			</form>
		</>
	);
};

const ForgotPasswordForm = (props) => {
	const [emailInput, setEmailInput] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

	const submit = async (e) => {
		e.preventDefault();
		if (isLoading) {
			return false;
		}

		setIsLoading(true);
		try {
			const res = await fetch(SERVER_URL + "/user/forgotpassword", {
				method: "POST",
				credentials: "include",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: emailInput,
				}),
			});
			const data = await res.json();
			setFormError(null);
			setIsLoading(false);
			console.log(data);
			if (data.error) {
				setFormError(data.error);
			}
			setHasBeenSubmitted(true);
		} catch (e) {
			console.log(e);
			setIsLoading(false);
			setFormError("Något gick fel! Försök igen senare.");
		}
		return false;
	};

	if (hasBeenSubmitted) {
		return (
			<>
				<p>
					Om det finns ett konto med e-postadressen {emailInput} så har ett
					e-postmeddelande skickats dit med instruktioner om hur du återställer
					ditt lösenord.
				</p>
			</>
		);
	}

	return (
		<>
			<form onSubmit={submit}>
				<FormInput
					type="email"
					name="email"
					value={emailInput}
					formId="login"
					label="E-postadress"
					setValue={setEmailInput}
					autocomplete="email"
					required
					minLength={2}
				/>
				<button type="submit" className="primary margin">
					{isLoading ? "Laddar..." : "Återställ lösenord"}
				</button>
				{formError && <div className="formerror">{formError}</div>}
			</form>
		</>
	);
};

const LoginWithEmailForm = ({ setSelectedMethod, onUserData }) => {
	const [emailInput, setEmailInput] = useState("");
	const [passwordInput, setPasswordInput] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);

	const submit = async (e) => {
		e.preventDefault();
		if (isLoading) {
			return false;
		}

		setIsLoading(true);
		try {
			const res = await fetch(SERVER_URL + "/auth/login", {
				method: "POST",
				credentials: "include",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: emailInput,
					password: passwordInput,
				}),
			});
			if (res.status === 401) {
				setIsLoading(false);
				setFormError("Ogiltig e-postadress eller lösenord!");
				return false;
			}
			const data = await res.json();
			setFormError(null);
			setIsLoading(false);
			console.log(data);
			if (data.user) {
				Cookies.set("ordel_user", JSON.stringify(data.user), { expires: 365 });
				onUserData(data);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
			setFormError("Något gick fel! Försök igen senare.");
		}
		return false;
	};

	return (
		<>
			<form onSubmit={submit}>
				<FormInput
					type="email"
					name="email"
					value={emailInput}
					formId="login"
					label="E-postadress"
					setValue={setEmailInput}
					autocomplete="email"
					required
					minLength={2}
				/>
				<FormInput
					type="password"
					name="password"
					formId="login"
					label="Lösenord"
					value={passwordInput}
					setValue={setPasswordInput}
					autocomplete="password"
					required
					minLength={8}
				/>
				<button type="submit" className="margin">
					{isLoading ? "Laddar..." : "Logga in"}
				</button>
				{formError && <div className="formerror">{formError}</div>}
				<button
					onClick={() => setSelectedMethod("forgot-password")}
					className="forgot-password"
					type="button"
				>
					Glömt lösenordet?
				</button>
			</form>
		</>
	);
};

const LoginForm = (props) => {
	const [selectedMethod, setSelectedMethod] = useState(null);

	if (selectedMethod) {
		if (selectedMethod === "email") {
			return (
				<>
					<h2>
						<button onClick={() => setSelectedMethod(null)}>
							<BackIcon />
						</button>{" "}
						Logga in med E-post
					</h2>
					<button
						onClick={() => setSelectedMethod("email-register")}
						className="big primary margin"
					>
						Skapa nytt konto
					</button>
					<div className="alt-space">eller</div>
					<LoginWithEmailForm
						onUserData={props.onUserData}
						setSelectedMethod={setSelectedMethod}
					/>
				</>
			);
		}
		if (selectedMethod === "email-register") {
			return (
				<>
					<h2>
						<button onClick={() => setSelectedMethod(null)}>
							<BackIcon />
						</button>{" "}
						Skapa nytt konto
					</h2>
					<RegisterWithEmailForm onUserData={props.onUserData} />
				</>
			);
		}
		if (selectedMethod === "forgot-password") {
			return (
				<>
					<h2>
						<button onClick={() => setSelectedMethod(null)}>
							<BackIcon />
						</button>{" "}
						Återställ lösenord
					</h2>
					<ForgotPasswordForm />
				</>
			);
		}
	}

	return (
		<>
			<h2>Mitt konto</h2>
			<p>
				Med ett Ordelkonto kan du spara din historik online och spela på olika
				enheter.
			</p>
			<div className="signin-options">
				<a href="/auth/login/google" className="button google">
					<img src="/google.svg" alt="" />
					Logga in med Google
				</a>
				<button onClick={() => setSelectedMethod("email")} className="big">
					Logga in med E-post
				</button>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					whiteSpace: "nowrap",
				}}
			>
				<a
					href="javascript:googlefc.callbackQueue.push(googlefc.showRevocationMessage)"
					className="privacy"
					style={{ marginRight: "10px" }}
				>
					Inställningar för cookies och integritet
				</a>
				<a
					href="https://ordel.se/privacy.html"
					target="_blank"
					rel="noopener noreferrer"
					className="privacy"
				>
					Integritetspolicy
				</a>
			</div>
		</>
	);
};

const LogoutForm = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);

	const submit = async (e) => {
		e.preventDefault();
		if (isLoading) {
			return false;
		}

		setIsLoading(true);
		try {
			const res = await fetch(SERVER_URL + "/auth/logout", {
				method: "POST",
				credentials: "include",
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
			});
			const data = await res.json();
			setFormError(null);
			setIsLoading(false);
			console.log(data);
			if (data.logoutSuccess) {
				props.onLogout();
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
			setFormError("Något gick fel! Försök igen senare.");
		}
		return false;
	};

	return (
		<form onSubmit={submit}>
			<button type="submit" className="margin">
				{isLoading ? "Laddar..." : "Logga ut"}
			</button>

			{formError && <div className="formerror">{formError}</div>}
		</form>
	);
};

const AccountWindow = (props) => {
	const onUserData = (data) => {
		if (getShouldShowPrompt(data)) {
			window.location.href = "/newuser";
			return;
		}
		props.updateUser(data);
	};

	const onLogout = () => {
		window.location.reload();
	};

	if (!props.user) {
		return <LoginForm onUserData={onUserData} />;
	}

	return (
		<div className="account-wrapper">
			<h2>Mitt konto</h2>
			<p>Inloggad som {props.user.user.displayName}</p>
			<p>✅ {props.user.history.length} sparade spel</p>
			<FormInput
				type="text"
				name="text"
				formId="disabled"
				label="E-post"
				value={props.user.user.username}
				setValue={() => {}}
				disabled
			/>
			<LogoutForm onLogout={onLogout} />
		</div>
	);
};

export default AccountWindow;
